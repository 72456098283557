/* ImageCarousel.css */
.logo-image {
    width: 20px;
}

.carousel-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Initial design for desktop */
    overflow: hidden;
}

.carousel-item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.text-container {
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width: 80%; /* Initial design for desktop */
}

/* Additional styling for headline (adjust as needed) */
.text-container p {
    font-size: 18px;
    margin-bottom: 10px;
}

.text-container h1 {
    font-family: 'Tilt Neon', 'Montserrat', 'Poetsen One', sans-serif;
    font-size: 50px;
    letter-spacing: 10px;
    color: #7f4d7e;
    font-weight: 900;
}

.text-container h2 {
    font-family: 'Tilt Neon', 'Montserrat', 'Poetsen One', sans-serif;
    font-size: 22px;
    color: #c06f6c;
    font-weight: 300;
    margin-top: -40px;
    white-space: pre-line;
}

.downButton {
    z-index: 1000;
}

h3 {
    color: #c06f6c;
}

.arrow-button {
    /* Common styles for both top and bottom arrows */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px; /* Adjust padding as needed */
    cursor: pointer;
}

.top-arrow {
    top: 10px; /* Position at the top */
}

.bottom-arrow {
    bottom: 10px; /* Position at the bottom */
}

.floating_headline {
    animation: floatUpDown 2s infinite alternate ease-in-out;
}

.floating_heartbeat {
    animation: heartbeat 5s infinite;
}

@keyframes floatUpDown {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0); /* Adjust the distance as desired */
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

/* ImageCarousel.css */

@media (max-width: 768px) {

    .background-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 700px;
        object-fit: cover; /* Maintain aspect ratio and cover the container */
    }

    .carousel-container {
        height: 200vh; /* Set height to cover the entire viewport height */
        max-height: none; /* Remove the maximum height restriction */
    }

    .text-container {
        top: 50%;
        width: 90%;
    }

    .text-container h1 {
        font-size: 4vw;
        letter-spacing: 0.5vw;
    }

    .text-container h2 {
        font-size: 2vw;
        margin-top: -2vw;
    }

    h3 {
        font-size: 2vw;
        margin-top: -2vw;
    }

    .arrow-button {
        padding: 2vw;
    }

    .top-arrow {
        top: 5vw;
    }

    .bottom-arrow {
        bottom: 5vw;
    }

    .image-container {
        width: 70px; /* Reduce size for smaller screens */
        height: 70px; /* Reduce size for smaller screens */
    }

    .image-container:hover {
        transform: scale(1.5); /* Reduce the scale on hover for smaller screens */
    }
}

.image-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    transition: transform 1s ease-in-out;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
}

.image-container:hover {
    transform: scale(2); /* Double the size on hover */
}

.image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

/* Additional Media Queries for Smartphones */
@media (max-width: 480px) {
    .text-container {
        top: 50%;
        width: 95%;
    }

    .text-container h1 {
        font-size: 6vw;
        letter-spacing: 1vw;
    }

    .text-container h2 {
        font-size: 3vw;
        margin-top: -3vw;
    }

    .arrow-button {
        padding: 3vw;
    }

    .top-arrow {
        top: 7vw;
    }

    .bottom-arrow {
        bottom: 7vw;
    }

    .image-container {
        width: 50px; /* Further reduce size for smaller screens */
        height: 50px; /* Further reduce size for smaller screens */
    }

    .image-container:hover {
        transform: scale(1.3); /* Further reduce the scale on hover for smaller screens */
    }
}
