body {
    margin: 0;
    font-family: 'Tilt Neon', 'Montserrat', 'Poetsen One', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('./data/img/bg_SE.svg'); /* Adjust path as per your project structure */
    background-size: cover; /* Ensures the background image covers the entire viewport */
    background-position: center; /* Centers the background image */
    background-repeat: no-repeat;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
